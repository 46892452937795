var $j = jQuery.noConflict();
$j(function(){
	$j('.secnav').setup_navigation();
});

$j.fn.setup_navigation = function(settings) {
	settings = jQuery.extend({
		menuHoverClass: 'show-menu'
	}, settings);
	
	$j(this).find('> li > a').hover(function(){
		$j(this).closest('ul.dropdownmenu').find('.'+settings.menuHoverClass).removeClass(settings.menuHoverClass);
	});
	$j(this).find('> li > a').focus(function(){
		$j(this).closest('ul.dropdown').find('.'+settings.menuHoverClass).removeClass(settings.menuHoverClass);
		$j(this).next('ul').addClass(settings.menuHoverClass);
	});
		
	// Hide menu if click occurs outside of navigation
	// Hide menu if click or focus occurs outside of navigation
	$j(this).find('.dropdownmenu li.last-child').keydown(function(e){ 
		if(e.keyCode == 9) {

			// If the user tabs out of the navigation hide all menus
			$j('.'+settings.menuHoverClass).removeClass(settings.menuHoverClass);
		}
	});
	$j(this).find('.dropdownmenu li').keydown(function(e){ 
		if(e.keyCode == 27) {

			// If the user tabs out of the navigation hide all menus
			$j('.'+settings.menuHoverClass).removeClass(settings.menuHoverClass);
		}
	});
	$j(document).click(function(){ $j('.'+settings.menuHoverClass).removeClass(settings.menuHoverClass); });
	
	$j(this).click(function(e){
		e.stopPropagation();
	});
};
